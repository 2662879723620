import React from 'react'
import { Link } from 'gatsby'

const MyComponent = ({ internal, external, thin, children }) => {
  if (external) {
    return (
      <a
        className="a-button"
        target="_blank"
        rel="noopener noreferrer"
        href={external}
      >
        {thin ? <span>{children}</span> : <h2>{children}</h2>}
      </a>
    )
  }
  return (
    <Link className="a-button" to={internal}>
      {thin ? <span>{children}</span> : <h2>{children}</h2>}
    </Link>
  )
}

export default MyComponent
