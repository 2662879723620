import React from 'react'
import Icon from 'components/atoms/icon'

export default () => {
  return (
    <div className="m-iconSet">
      <Icon
        modifiers={['-instagram']}
        external="https://www.instagram.com/sieni_kochi/"
      />
    </div>
  )
}
