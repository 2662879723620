import React from 'react'
import { Link } from 'gatsby'
import classnames from 'classnames'
import Logo from 'components/atoms/logo'

export default ({ modifiers, left, right }) => (
  <header className={classnames('m-header', ...modifiers)}>
    <div className="m-header__container">
      <div className="m-header__side -left">{left}</div>
      <div className="m-header__center">
        <Link to="/">
          <Logo />
        </Link>
      </div>
      <div className="m-header__side -right">{right}</div>
    </div>
  </header>
)
