import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import Img from 'gatsby-image'

export default ({ size }) => {
  const {
    file: {
      childImageSharp: { small, large },
    },
  } = useStaticQuery(
    graphql`
      query {
        file(relativePath: { regex: "/sieni/" }) {
          childImageSharp {
            small: fixed(width: 80) {
              ...GatsbyImageSharpFixed_withWebp
            }
            large: fixed(width: 320) {
              ...GatsbyImageSharpFixed_withWebp
            }
          }
        }
      }
    `
  )
  if (size && size === 'large') {
    return <Img fixed={large} />
  }
  return <Img fixed={small} />
}
