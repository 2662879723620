import React from 'react'
import Button from 'components/atoms/button'
import { graphql, useStaticQuery } from 'gatsby'
// import { css } from '@emotion/core'
// import { Link } from 'gatsby'
// import Button from 'components/button'
import IconSet from 'components/molecules/iconSet'

export default () => {
  const {
    site: {
      siteMetadata: { onlineShop },
    },
  } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            onlineShop
          }
        }
      }
    `
  )
  return (
    <div className="m-navigationFooter">
      <Button external={onlineShop}>ONLINE SHOP</Button>
      <IconSet />
    </div>
  )
}
