import React from 'react'
import { graphql } from 'gatsby'
import Img from 'gatsby-image/withIEPolyfill'
import { css } from '@emotion/core'

import Layout from 'templates/layout'
import SEO from 'components/seo'
import Logo from 'components/atoms/logo'
import Section from 'components/organisms/section'
import Button from 'components/atoms/button'
// import Map from 'components/map'

export default ({
  data: {
    background,
    map,
    markdownRemark: {
      frontmatter: {
        about,
        shopInfo: { postalCode, address, tel, mail },
        onlineShop: { image, url },
      },
    },
  },
}) => (
  <Layout>
    <SEO title="sieniについて" description={about} />
    <Img
      className="t-about__background"
      fluid={background.childImageSharp.fluid}
      objectFit="cover"
      style={{
        position: 'fixed',
        width: '100%',
        height: '100%',
        opacity: 0.2,
      }}
    />
    <div className="t-about">
      <div className="t-about__side">
        <Img fluid={background.childImageSharp.fluid} />
      </div>
      <div className="t-about__container">
        <Section title="About" sub="sieniについて">
          <div className="t-about__logo">
            <Logo />
          </div>
          <div className="t-about__text">{about}</div>
        </Section>
      </div>
      <div className="t-about__container" id="shop_info">
        <Section title="Shop Info." sub="店舗情報">
          <div className="t-about__shopInfo">
            <div className="t-about__map">
              <Img fluid={map.childImageSharp.fluid} />
            </div>
            <div className="t-about__mapLink">
              <Button
                thin
                external="https://www.google.com/maps/search/?api=1&query=33.560190, 133.537975&query_place_id=ChIJ-aTw3zkZTjUReHK7KTW7uCI"
              >
                Google mapで開く
              </Button>
            </div>
            <div className="t-about__address">
              <p>〒{postalCode}</p>
              <p>{address}</p>
              <table
                css={css`
                  text-align: left;
                  th:not(:first-of-type) {
                    padding-left: 1em;
                  }
                `}
              >
                <tbody>
                  <tr>
                    <th>TEL</th>
                    <th>{tel}</th>
                  </tr>
                  <tr>
                    <th>MAIL</th>
                    <th>{mail}</th>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </Section>
      </div>
    </div>
    <div className="t-about__onlineShop">
      <Img
        fluid={image.childImageSharp.fluid}
        objectFit="cover"
        style={{
          position: 'absolute',
          width: '100%',
          height: '100%',
        }}
      />
      <a
        target="_blank"
        rel="noopener noreferrer"
        href={url}
        css={({ color }) => css`
          position: relative;
          border: solid ${color.white};
          padding: 1em 4em;
          display: flex;
          justify-content: center;
          align-items: center;
          color: ${color.white};
          background-color: rgba(0, 0, 0, 0.24);
          text-decoration: none;
        `}
      >
        <h2>ONLINE SHOP</h2>
      </a>
    </div>
  </Layout>
)

export const query = graphql`
  query {
    background: file(relativePath: { regex: "/background/" }) {
      childImageSharp {
        fluid(maxWidth: 1000) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    map: file(relativePath: { regex: "/shop_map/" }) {
      childImageSharp {
        fluid(maxWidth: 1000) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    markdownRemark(frontmatter: { templateKey: { eq: "about" } }) {
      frontmatter {
        about
        shopInfo {
          postalCode
          address
          tel
          mail
          lat
          lng
        }
        onlineShop {
          image {
            childImageSharp {
              fluid(maxWidth: 1000) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
          url
        }
      }
    }
  }
`
