import React from 'react'

const Section = ({ children, title, sub }) => (
  <div className="o-section">
    <h1>{title}</h1>
    <div className="o-section__sub">
      <span>{sub}</span>
    </div>
    <div className="o-section__contents">{children}</div>
  </div>
)

export default Section
