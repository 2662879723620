import React from 'react'
import IconSet from 'components/molecules/iconSet'

export default () => {
  return (
    <footer className="m-footer">
      <div className="m-footer__top">
        <IconSet />
      </div>
      <div className="m-footer__bottom">
        <span>© {new Date().getFullYear()}, sieni All rights reserved.</span>
      </div>
    </footer>
  )
}
