import React, { useState } from 'react'
import { CSSTransition } from 'react-transition-group'
import Hamburger from 'components/atoms/hamburger'
import NavigationMenu from 'components/molecules/navigationMenu'
import NavigationFooter from 'components/molecules/navigationFooter'

export default () => {
  const [isVisible, switchDrawer] = useState(false)

  return (
    <>
      <CSSTransition
        in={isVisible}
        timeout={400}
        unmountOnExit
        classNames="-fade"
      >
        <div className="o-drawer__mask" />
      </CSSTransition>
      <CSSTransition
        in={isVisible}
        timeout={400}
        unmountOnExit
        classNames="-fade"
      >
        <div className="o-drawer__menuContainer">
          <NavigationMenu />
          <NavigationFooter />
        </div>
      </CSSTransition>
      <Hamburger
        isNegative={isVisible}
        onClick={() => switchDrawer(!isVisible)}
      />
    </>
  )
}
