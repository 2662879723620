import React from 'react'
import times from 'lodash/times'
import { CSSTransition } from 'react-transition-group'

export default ({ onClick, isNegative }) => (
  <div
    aria-hidden
    role="button"
    className="a-hamburger"
    onClick={onClick}
    onKeyPress={onClick}
  >
    {times(3).map(() => (
      <CSSTransition in={isNegative} timeout={400} classNames="-negative">
        <span className="a-hamburger__line" />
      </CSSTransition>
    ))}
  </div>
)
