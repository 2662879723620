import React from 'react'
// import { css } from '@emotion/core'
// import { Link } from 'gatsby'
import Navigation from 'components/atoms/navigation'
// import Button from 'components/button'

export default () => (
  <div className="m-navigationMenu">
    <Navigation to="/">Top</Navigation>
    <Navigation to="/brands">Brand</Navigation>
    <Navigation to="/styles">Styling</Navigation>
    <Navigation to="/about">About</Navigation>
    <Navigation to="/about#shop_info">Shop Info.</Navigation>
  </div>
)
