import React from 'react'
import classnames from 'classnames'

export default ({ modifiers, external }) => {
  if (external) {
    return (
      <a target="_blank" rel="noopener noreferrer" href={external}>
        <div className={classnames('a-icon', ...modifiers)} />
      </a>
    )
  }
  return <div className={classnames('a-icon', ...modifiers)} />
}
