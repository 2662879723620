/**
 * Layout component that queries for data
 * with Gatsby's StaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/static-query/
 */

import React from 'react'
// import PropTypes from 'prop-types'
import { graphql, useStaticQuery } from 'gatsby'
// import { css } from '@emotion/core'
import { ThemeProvider } from 'emotion-theming'
// import NavigationMenu from 'components/molecules/navigationMenu'
// import Img from 'gatsby-image/withIEPolyfill'
import Navigation from 'components/atoms/navigation'
// import Hamburger from 'components/atoms/hamburger'
// import Header from 'components/molecules/header'
import Drawer from 'components/organisms/drawer'
import Header from 'components/molecules/header'
import Footer from 'components/molecules/footer'
import IconSet from 'components/molecules/iconSet'

// import Button from '../components/atoms/button'
// import './layout.css'

const theme = {
  color: {
    white: '#fcfcfc',
    light_gray: '#f2f2f2',
    lighter_gray: '#f3f7fa',
    brownish_grey: '#797272',
    greyish_brown: '#484444',
    black: '#3d3d3d',
  },
}

// Layout.propTypes = {
//   children: PropTypes.node.isRequired,
// }

export default ({ children, hideHeader }) => {
  // const { logo } = useStaticQuery(
  //   graphql`
  //     query {
  //       logo: file(relativePath: { regex: "/sieni/" }) {
  //         childImageSharp {
  //           fluid(maxWidth: 1000) {
  //             ...GatsbyImageSharpFluid_withWebp
  //           }
  //         }
  //       }
  //     }
  //   `
  // )

  // const [drawer, switchDrawer] = useState(false)

  const {
    site: {
      siteMetadata: { onlineShop },
    },
  } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            onlineShop
          }
        }
      }
    `
  )

  return (
    <ThemeProvider theme={theme}>
      <main className={`t-layout${!hideHeader ? ' -withHeader' : ''}`}>
        {children}
        <Footer />
      </main>
      <Header
        modifiers={hideHeader && ['-hide']}
        left={
          <>
            <Navigation to="/brands">Brand</Navigation>
            <Navigation to="/about">About</Navigation>
            <Navigation to="/styles">Styling</Navigation>
            <Navigation to="/about#shop_info">Shop Info.</Navigation>
            <Navigation external={onlineShop}>ONLINE SHOP</Navigation>
          </>
        }
        right={<IconSet />}
      />
      <Drawer />
    </ThemeProvider>
  )
}
