import React from 'react'
import { Link } from 'gatsby'

export default ({ external, to, children }) => {
  if (external) {
    return (
      <div className="a-navigation">
        <a
          className="a-navigation__link"
          target="_blank"
          rel="noopener noreferrer"
          href={external}
        >
          <h3>{children}</h3>
        </a>
      </div>
    )
  }
  return (
    <div className="a-navigation">
      <Link className="a-navigation__link" to={to}>
        <h3>{children}</h3>
      </Link>
    </div>
  )
}
